var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"elevation-0"},[_c('v-card-title',[_vm._v(" Users "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","hint":"Username, Email, Full Name (press ENTER to search)","label":"Search"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.searchOnEnter.apply(null, arguments)},"blur":_vm.searchOnEnter}})],1),_c('v-data-table',{staticClass:"elevation-0",attrs:{"single-select":!_vm.multiSelect,"show-select":_vm.multiSelect,"headers":_vm.headers,"items":_vm.usersFiltered,"page":_vm.currentPage,"items-per-page":_vm.pageSize,"hide-default-footer":""},on:{"update:page":function($event){_vm.currentPage=$event},"page-count":function($event){_vm.totalTablePages =_vm.totalPaginationPages}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',{staticClass:"ml-4 mr-4",attrs:{"align":"center"}},[_c('v-switch',{staticClass:"pa-3",attrs:{"label":_vm.multiSelect?'Multi Select':'Single Select',"x-small":""},model:{value:(_vm.multiSelect),callback:function ($$v) {_vm.multiSelect=$$v},expression:"multiSelect"}}),(false)?_c('v-btn',{staticClass:"mr-2",attrs:{"fab":"","dark":"","color":"red accent-4","x-small":"","disabled":!_vm.multiSelect},on:{"click":_vm.deleteDialogMultiShow}},[_c('v-icon',[_vm._v("mdi-delete")])],1):_vm._e(),_c('v-spacer'),_c('v-btn',{attrs:{"fab":"","dark":"","color":"green","x-small":"","disabled":_vm.multiSelect},on:{"click":_vm.showDialogNew}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1)]},proxy:true},{key:"item.username",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"fill-height"},[_c('div',{staticClass:"subtitle-2"},[_vm._v(" "+_vm._s(item.username)+" ")]),_c('div',{staticClass:"mt-1 grey--text"},[_vm._v(" "+_vm._s(item.fullName)+" ")])])]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"fill-height"},[_c('div',[_vm._v(" "+_vm._s(item.email)+" ")]),(item.phone>821999)?_c('div',[_c('v-btn',{staticClass:"no-gutters",attrs:{"x-small":"","plain":"","href":("https://api.whatsapp.com/send?phone=+62" + (item.phone) + "&text=Hi kak,"),"target":"_blank"}},[_c('v-icon',{attrs:{"x-small":"","color":"green"}},[_vm._v("mdi-whatsapp")]),_c('span',{staticClass:"caption"},[_vm._v(" 0"+_vm._s(item.phone)+" ")])],1)],1):_vm._e()])]}},{key:"item.roles",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"fill-height caption"},[_vm._v(" "+_vm._s(_vm.rolesToArray(item.roles))+" ")])]}},{key:"item.fcustomerIds",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"fill-height caption"},[_vm._v(" "+_vm._s(_vm.convertToCustomer(item.fcustomerIds))+" ")])]}},{key:"item.fullName",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"fill-height"},[_c('div',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(item.fullName)+" ")]),(_vm.roleAgenGroup(item.roles) && item.salesmanOf>0)?_c('div',{staticClass:"font-weight-bold"},[_c('v-flex',{staticClass:"d-flex align-baseline blue--text"},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-cart")]),_vm._v(" "+_vm._s(_vm.lookupFSalesman(item.salesmanOf).spname)+" ")],1)],1):(_vm.roleAgenGroup(item.roles) && item.salesmanOf ===0)?_c('div',[_c('span',{staticClass:"red--text text-decoration-line-through"},[_vm._v("salesman")]),_vm._v(" "),_c('v-icon',{attrs:{"small":"","color":"red"}},[_vm._v("mdi-exclamation")])],1):_vm._e()])]}},{key:"item.fdivisionBean",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"fill-height"},[_c('tr',{staticClass:"caption"},[_vm._v(" "+_vm._s(_vm.lookupFDivision(item.fdivisionBean))+" ")]),_c('tr',[_c('v-chip',{attrs:{"color":_vm.getColorOrganizationLevel(item.organizationLevel),"x-small":""}},[_vm._v(" "+_vm._s(item.organizationLevel)+" ")]),_c('v-chip',{attrs:{"color":_vm.getColorStatusActive(item.isAccountNonLocked),"dark":"","x-small":""}},[_vm._v(" "+_vm._s(item.isAccountNonLocked===true?'Aktif': 'Locked')+" ")])],1)])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","disabled":_vm.multiSelect},on:{"click":function($event){return _vm.showDialogEdit(item)}}},[_c('v-icon',{attrs:{"small":"","color":"warning"}},[_vm._v(" mdi-pencil ")])],1),_c('v-btn',{attrs:{"icon":"","disabled":_vm.multiSelect},on:{"click":function($event){return _vm.deleteDialogShow(item)}}},[_c('v-icon',{attrs:{"small":"","color":"red accent-4"}},[_vm._v(" mdi-delete ")])],1)]}}],null,true),model:{value:(_vm.selectedItems),callback:function ($$v) {_vm.selectedItems=$$v},expression:"selectedItems"}}),_c('v-container',[_c('v-row',{attrs:{"justify":"end","align":"center"}},[_c('v-col',{attrs:{"cols":"4","md":"2","sm":"2"}},[_c('v-select',{attrs:{"items":_vm.pageSizes,"label":"Items per page"},model:{value:(_vm.pageSize),callback:function ($$v) {_vm.pageSize=$$v},expression:"pageSize"}})],1),_c('v-col',{attrs:{"cols":"10","md":"9","sm":"8","align":"right"}},[_c('v-pagination',{attrs:{"length":_vm.totalPaginationPages,"total-visible":"8","circle":""},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1)],1),_c('DeleteDialog',{ref:"refDeleteConfirmDialog",on:{"eventFromDeleteConfirmDialog1":_vm.deleteItemConfirmedSingleSelect,"eventFromDeleteConfirmDialog2":_vm.deleteItemConfirmedMultiSelect}}),_c('UsersDialog',{ref:"refFormDialog",attrs:{"formMode":_vm.formMode,"itemsFCompany":_vm.itemsFCompany,"itemsFCustomer":_vm.itemsFCustomer},on:{"update:formMode":function($event){_vm.formMode=$event},"update:form-mode":function($event){_vm.formMode=$event},"eventFromFormDialogNew":_vm.saveDataNew,"eventFromFormDialogEdit":_vm.saveDataEdit}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }